import React, { useState } from "react";
import AddProduct from "../components/AddProduct";
import ConfirmOrders from "../components/ConfirmOrders";
import Sidebar from "../components/Sidebar";
import Checkout from "../components/Checkouts";
import Nav from "../components/Nav";

const AdminHome = () => {
  const [selectedPage, setSelectedPage] = useState("addProduct");

  const renderPage = () => {
    console.log("Rendering page:", selectedPage);
    switch (selectedPage) {
      case "addProduct":
        return <AddProduct />;
      case "confirmOrders":
        return <ConfirmOrders />;
      case "checkout":
        return <Checkout />;
      default:
        return <div>Error: Page not found</div>;
    }
  };

  return (
    <div>
      <Nav />
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-md-3 col-lg-2 d-md-block bg-light sidebar">
            <Sidebar selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
          </div>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-4">
            {renderPage()}
          </main>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
