export const loadState = () => {
    try {
      const serializedState = localStorage.getItem("admin");
      if (!serializedState) return undefined;
      return { admin: { admin: JSON.parse(serializedState) } };
    } catch (err) {
      console.error("Error loading state:", err);
      return undefined;
    }
  };
  
  export const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state.admin?.admin || null);
      localStorage.setItem("admin", serializedState);
    } catch (err) {
      console.error("Error saving state:", err);
    }
  };
  