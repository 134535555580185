import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
   // Retrieve the admin object from Redux store
   const user = useSelector(state => state.auth.user);
 

  const isAdmin = user?.role === "admin";

  if (!isAdmin) {
    console.log("No admin user found, redirecting to login.");
    return <Navigate to="/admin/login" />;
  }

  return children;
};

export default ProtectedRoute;
