import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/action";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import { ADMIN_API_END_POINT } from "../utils/constant";

const ProductSkeleton = React.memo(() => (
  <div className="col-md-4 col-sm-6 col-12 mb-4">
    <Skeleton height={592} />
  </div>
));

const Mainitem = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const fetchProducts = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${ADMIN_API_END_POINT}/list-products`);
      const products = response.data.products;

      // Sorting products by the most recent and selecting the latest 6
      const latestProducts = products
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 6);

      setData(latestProducts);
      setError(null);
    } catch (err) {
      setError("Failed to load products. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const addProductToCart = useCallback(
    (product) => {
      dispatch(addCart(product));
      toast.success("Added to cart");
    },
    [dispatch]
  );

  return (
    <>
      <div className="container my-3 py-3">
        <h2 className="text-center mb-4">Latest Products</h2>
        <div className="row">
          {loading &&
            Array.from({ length: 6 }, (_, i) => <ProductSkeleton key={i} />)}
          {error && <div className="col-12 text-danger text-center">{error}</div>}
          {!loading &&
            !error &&
            data.map((product) => (
              <div key={product._id} className="col-md-4 col-sm-6 col-12 mb-4">
                <div className="card text-center h-100">
                  <img
                    className="p-2 object-fit-cover"
                    src={product.image?.url || "https://via.placeholder.com/300"}
                    alt={product.title}
                    loading="lazy"
                    height={300}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{product.title.substring(0, 12)}...</h5>
                    <p className="card-text">{product.description.substring(0, 90)}...</p>
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item lead">Rs {product.price}</li>
                    <li className="list-group-item">
                      Rating: {product.rating?.rate}★
                    </li>
                  </ul>
                  <div className="card-body">
                    <Link to={`/product/${product._id}`} className="btn btn-dark m-1">
                      Buy Now
                    </Link>
                    <button
                      className="btn btn-dark m-1"
                      onClick={() => addProductToCart(product)}
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            ))}
          {!loading && data.length === 0 && !error && (
            <div className="col-12 text-center">No products found.</div>
          )}
        </div>
      </div>
    </>
  );
};

export default Mainitem;
