import { combineReducers } from 'redux';
import handleCart from './handleCart'; // Your existing reducer
import authReducer from './authSlice'; // Assuming this is another slice


const rootReducers = combineReducers({
  handleCart,
  auth: authReducer,

});

export default rootReducers;
