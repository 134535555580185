import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/action";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import Navbar from "./Navbar";
import { ADMIN_API_END_POINT } from "../utils/constant";
import axios from "axios";

const Products = () => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterCategory, setFilterCategory] = useState("All");
  const [priceRange, setPriceRange] = useState(1000);
  const [minRating, setMinRating] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const filteredData = useMemo(() => {
    let filtered = data;
    if (filterCategory !== "All") {
      filtered = filtered.filter((item) => item.category === filterCategory);
    }
    filtered = filtered.filter(
      (item) =>
        item.price <= priceRange &&
        item.rating.rate >= minRating &&
        (item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.description.toLowerCase().includes(searchQuery.toLowerCase()))
    );
    return filtered;
  }, [data, filterCategory, priceRange, minRating, searchQuery]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${ADMIN_API_END_POINT}/list-products`);
        console.log("API Response:", response.data);
        setData(response.data.products);
        setError(null);
      } catch (err) {
        console.error("API Error:", err); // Log the full error
        setError(`Failed to load products. Error: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const addProductToCart = useCallback(
    (product) => {
      dispatch(addCart(product));
      toast.success("Added to cart");
    },
    [dispatch]
  );

  const filterCategories = useMemo(
    () => ["All", "men's clothing", "women's clothing", "jewelery", "electronics"],
    []
  );

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <div className="row mb-4">
          <div className="col-md-12">
            <input
              type="text"
              className="form-control"
              placeholder="Search products..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div>
              <h5>Filter by Category</h5>
              <div className="list-group mb-4">
                {filterCategories.map((category, index) => (
                  <button
                    key={index}
                    className={`list-group-item list-group-item-action ${
                      filterCategory === category ? "active" : ""
                    }`}
                    onClick={() => setFilterCategory(category)}
                  >
                    {category}
                  </button>
                ))}
              </div>
              <h5>Filter by Price</h5>
              <div className="mb-4">
                <input
                  type="range"
                  className="form-range"
                  min="0"
                  max="1000"
                  step="50"
                  value={priceRange}
                  onChange={(e) => setPriceRange(Number(e.target.value))}
                />
                <p className="text-center">Up to: Rs {priceRange}</p>
              </div>
              <h5>Filter by Rating</h5>
              <div className="mb-4">
                <input
                  type="range"
                  className="form-range"
                  min="0"
                  max="5"
                  step="0.5"
                  value={minRating}
                  onChange={(e) => setMinRating(Number(e.target.value))}
                />
                <p className="text-center">Minimum Rating: {minRating}★</p>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="row justify-content-center">
              {loading &&
                Array.from({ length: 6 }).map((_, index) => (
                  <div key={`skeleton-${index}`} className="col-md-4 col-sm-6 col-12 mb-4">
                    <Skeleton height={592} />
                  </div>
                ))}
              {error && <div className="col-12 text-danger text-center">{error}</div>}
              {!loading &&
                !error &&
                filteredData.map((product) => (
                  <div key={product._id} className="col-md-4 col-sm-6 col-12 mb-4">
                    <div className="card text-center h-100">
                      <img
                        className="card-img-top p-3"
                        src={product.image?.url || "https://via.placeholder.com/300"}
                        alt={product.title}
                        loading="lazy"
                        height={300}
                      />
                      <div className="card-body">
                        <h5 className="card-title">
                          {product.title.substring(0, 12)}...
                        </h5>
                        <p className="card-text">
                          {product.description.substring(0, 90)}...
                        </p>
                      </div>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item lead">Rs {product.price}</li>
                        <li className="list-group-item">
                          Rating: {product.rating.rate}★
                        </li>
                      </ul>
                      <div className="card-body">
                        <Link to={`/product/${product._id}`} className="btn btn-dark m-1">
                          Buy Now
                        </Link>
                        <button
                          className="btn btn-dark m-1"
                          onClick={() => addProductToCart(product)}
                        >
                          Add to Cart
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              {!loading && filteredData.length === 0 && !error && (
                <div className="col-12 text-center">No products found.</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
