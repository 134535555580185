import React from "react";

const ConfirmOrders = () => {
  const orders = [
    { id: 1, productName: "Product A", status: "Pending" },
    { id: 2, productName: "Product B", status: "Pending" },
  ];

  console.log("Orders:", orders);


  const handleConfirm = (id) => {
    alert(`Order ${id} confirmed!`);
    console.log(`Order ${id} confirmed`);
  };

  return (
    <div className="card shadow mt-3">
      <div className="card-header bg-primary text-white">
        <h4>Confirm Orders</h4>
      </div>
      <div className="card-body">
        <ul className="list-group">
          {orders?.length > 0 ? (
            orders.map((order) => (
              <li
                key={order.id}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                {order.productName}
                <button
                  onClick={() => handleConfirm(order.id)}
                  className="btn btn-success btn-sm"
                >
                  Confirm
                </button>
              </li>
            ))
          ) : (
            <p>No orders available</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ConfirmOrders;
