import React, { useState } from 'react';
import axios from 'axios';
import { Footer, Navbar } from "../components";
import { Link, useNavigate } from 'react-router-dom';
import { USER_API_END_POINT } from '../utils/constant';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        phoneNumber: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        
        try {
            const res = await axios.post(`${USER_API_END_POINT}/register`, formData, {
                headers: { 'Content-Type': "application/json" },
                withCredentials: true,
            });

            if (res.data.success) {
                toast.success(res.data.message);
                navigate("/login",{ state: { successMessage: res.data.message } });
            }
        } catch (error) {
            console.error(error);
            toast.error(error.response?.data?.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Navbar />
            <div className="container my-3 py-3">
                <h1 className="text-center">Register</h1>
                <hr />
                <div className="row my-4 h-100">
                    <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
                        <form onSubmit={handleSubmit}>
                            <div className="form my-3">
                                <label htmlFor="fullname" className="block mb-2 font-medium text-gray-700">Full Name</label>
                                <input
                                    type="text"
                                    className="form-control px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-200"
                                    id="fullname"
                                    placeholder="Enter Your Name"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form my-3">
                                <label htmlFor="email" className="block mb-2 font-medium text-gray-700">Email address</label>
                                <input
                                    type="email"
                                    className="form-control px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-200"
                                    id="email"
                                    placeholder="name@example.com"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form my-3">
                                <label htmlFor="phoneNumber" className="block mb-2 font-medium text-gray-700">Phone Number</label>
                                <input
                                    type="tel"
                                    className="form-control px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-200"
                                    id="phoneNumber"
                                    placeholder="Enter Your Phone Number"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form my-3">
                                <label htmlFor="password" className="block mb-2 font-medium text-gray-700">Password</label>
                                <input
                                    type="password"
                                    className="form-control px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-200"
                                    id="password"
                                    placeholder="Password"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                            </div>
                            {error && <div className="alert alert-danger mt-2" role="alert">{error}</div>}
                            <div className="my-3">
                                <p>Already have an account? <Link to="/login" className="text-decoration-underline text-info">Login</Link></p>
                            </div>
                            <div className="text-center">
                                <button className="my-2 mx-auto btn btn-dark px-6 py-2 rounded-lg shadow-md hover:bg-gray-800" type="submit" disabled={loading}>
                                    {loading ? 'Registering...' : 'Register'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
            <ToastContainer />
        </>
    );
};

export default Register;
