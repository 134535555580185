import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { ADMIN_API_END_POINT } from "../utils/constant";
import { Navbar } from "../components";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/action"; // Assuming addCart is defined in your actions
import toast from "react-hot-toast";

const Product = () => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  
  const dispatch = useDispatch();

  useEffect(() => {
    const getProduct = async () => {
      setLoading(true);
      setLoading2(true);
      try {
        const response = await fetch(`${ADMIN_API_END_POINT}/product/${id}`);
        const data = await response.json(); 
        console.log(data); // Debugging log to check the parsed data

        setProduct(data.product);
        setLoading(false);

        const response2 = await fetch(
          `https://fakestoreapi.com/products/category/${data.category}`
        );
        const data2 = await response2.json();
        setSimilarProducts(data2);
        setLoading2(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
        setLoading2(false);
      }
    };

    getProduct();
  }, [id]);

  // Function to add product to the cart
  const addProductToCart = (product) => {
    dispatch(addCart(product));
    toast.success("Added to cart");
  };

  const ShowProduct = () => {
    if (loading) {
      return <div className="text-center my-5">Loading Product...</div>;
    }
    return (
      <div className="container my-5 shadow-lg p-4 rounded">
        <div className="row">
          <div className="col-md-6 text-center">
            <img
              src={product.image?.url || "https://via.placeholder.com/300"}
              alt={product.title}
              className="img-fluid object-fit-cover"
              style={{ maxHeight: "400px" }}
            />
          </div>
          <div className="col-md-6">
            <h2>{product.title}</h2>
            <p className="text-muted">{product.category}</p>
            <p>{product.description}</p>
            <h3 className="text-success">${product.price}</h3>
            <button
              className="btn btn-primary btn-lg mt-3"
              onClick={() => addProductToCart(product)} // Add to cart logic
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    );
  };

  const ShowSimilarProduct = () => {
    if (loading2) {
      return <div className="text-center my-5">Loading Similar Products...</div>;
    }
    if (!similarProducts || similarProducts.length === 0) {
      return <p className="text-center">No similar products found.</p>;
    }
    return (
      <div className="container my-5 shadow-lg p-4 rounded">
        <div className="row">
          {similarProducts.map((item) => (
            <div key={item.id} className="col-md-3 mb-4">
              <div className="card h-100 text-center shadow-sm">
                <img
                  className="card-img-top p-3"
                  src={item.image}
                  alt={item.title}
                  style={{ maxHeight: "200px", objectFit: "contain" }}
                />
                <div className="card-body">
                  <h5 className="card-title">
                    {item.title.length > 15
                      ? `${item.title.substring(0, 15)}...`
                      : item.title}
                  </h5>
                </div>
                <div className="card-footer">
                  <Link to={`/product/${item.id}`} className="btn btn-dark btn-sm">
                    Buy Now
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Navbar />
      <ShowProduct />
      <h3 className="text-center my-5">Similar Products</h3>
      <ShowSimilarProduct />
    </div>
  );
};

export default Product;
