import React, { useState, useEffect } from "react";
import { Footer, Navbar } from "../components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { BUSINESS_API_END_POINT, CHECKOUT_API_END_POINT } from "../utils/constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
  const state = useSelector((state) => state.handleCart);
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate(); 
  

  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [isFetchingQr, setIsFetchingQr] = useState(true);
  const [showModal, setShowModal] = useState(false);

  // Fetch the QR code on component load
  useEffect(() => {
    setIsFetchingQr(true);
    axios
      .get(`${BUSINESS_API_END_POINT}/get-business-qr?paymentMethod=default`)
      .then((response) => {
        const qrCode = response.data.qrCodes[0]?.url;
        if (qrCode) setQrCodeUrl(qrCode);
      })
      .catch((error) => {
        console.error("Error fetching QR codes:", error);
        toast.error("Failed to fetch QR code. Please try again.");
      })
      .finally(() => {
        setIsFetchingQr(false);
      });
  }, []);

  // Form validation schema using Yup
  const validationSchema = Yup.object({
    senderNumber: Yup.string().required("Sender number is required"),
    remarks: Yup.string(),
    image: Yup.mixed().required("File is required").nullable(), 
  });

  // Handle form submission
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
  
      // Ensure user._id exists, use "guest" if not available
      const userId = user?._id || "guest";
  
      // Create the FormData object and append form data
      const formData = new FormData();
      formData.append("userId", userId); 
      formData.append("remarks", values.remarks);
      formData.append("senderNumber", values.senderNumber);
      formData.append("image", values.file);
  
      // Append only the product IDs to the form data
      const productIds = state.map((item) => item._id);
      formData.append("productIds", JSON.stringify(productIds)); // Convert to JSON for safe transfer
  
      
  
      // Send the form data to the backend via POST request
      await axios.post(`${CHECKOUT_API_END_POINT}/add`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
  
      toast.success("Checkout successful!");
      setShowModal(false);
      // Navigate to /usercheckout on success
      navigate(`/usercheckout/${userId}`);
    } catch (error) {
      console.error("Error during checkout:", error);
      toast.error("Something went wrong. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };
  
  // Display empty cart message
  const EmptyCart = () => (
    <div className="container">
      <div className="row">
        <div className="col-md-12 py-5 bg-light text-center">
          <h4 className="p-3 display-5">No item in Cart</h4>
          <Link to="/" className="btn btn-outline-dark mx-4">
            <i className="fa fa-arrow-left"></i> Continue Shopping
          </Link>
        </div>
      </div>
    </div>
  );

  // Display checkout form when cart has items
  const ShowCheckout = () => {
    let subtotal = 0;
    let shipping = 30.0;
    let totalItems = 0;

    state.forEach((item) => {
      subtotal += item.price * item.qty;
      totalItems += item.qty;
    });

    return (
      <div className="container py-5">
        {isFetchingQr ? (
          <div className="row my-4">
            <div className="col-md-12 text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p className="mt-2">Loading payment QR code...</p>
            </div>
          </div>
        ) : (
          qrCodeUrl && (
            <div className="row my-4">
              <div className="col-md-12">
                <div className="card mb-4">
                  <div className="card-header py-3">
                    <h4 className="mb-0">Payment QR Code</h4>
                  </div>
                  <div className="card-body text-center">
                    <img
                      src={qrCodeUrl}
                      alt="QR Code"
                      className="img-fluid rounded"
                      style={{ maxWidth: "300px", height: "auto" }}
                    />
                    <p className="mt-3">Scan this QR code for payment.</p>
                  </div>
                </div>
              </div>
            </div>
          )
        )}

        <div className="row my-4">
          <div className="col-md-5 col-lg-4 order-md-last">
            <div className="card mb-4">
              <div className="card-header py-3 bg-light">
                <h5 className="mb-0">Order Summary</h5>
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                    Products ({totalItems})<span>${Math.round(subtotal)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                    Shipping<span>${shipping}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                    <div>
                      <strong>Total amount</strong>
                    </div>
                    <span>
                      <strong>${Math.round(subtotal + shipping)}</strong>
                    </span>
                  </li>
                </ul>
                <button
                  className="btn btn-primary w-100"
                  onClick={() => setShowModal(true)}
                >
                  Proceed to Checkout
                </button>
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <div
            className="modal fade show d-block"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            tabIndex="-1"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Complete Your Order</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setShowModal(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <Formik
                    initialValues={{
                      remarks: "",
                      senderNumber: "",
                      image: null, // Added file field
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ setFieldValue, values, touched, errors, isSubmitting }) => (
                      <Form>
                        <div className="mb-3">
                          <label htmlFor="remarks" className="form-label">
                            Remarks
                          </label>
                          <Field
                            as="textarea"
                            id="remarks"
                            name="remarks"
                            className="form-control"
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="senderNumber" className="form-label">
                            Sender Number
                          </label>
                          <Field
                            type="text"
                            id="senderNumber"
                            name="senderNumber"
                            className="form-control"
                          />
                          {touched.senderNumber && errors.senderNumber && (
                            <div className="text-danger">{errors.senderNumber}</div>
                          )}
                        </div>
                        <div className="mb-3">
                          <label htmlFor="file" className="form-label">
                            Upload Image
                          </label>
                          <input
                            type="file"
                            id="file"
                            name="image"
                            className="form-control"
                            accept="image/*"
                            onChange={(e) => setFieldValue("file", e.target.files[0])} // Handling file selection
                          />
                          {touched.file && errors.file && (
                            <div className="text-danger">{errors.file}</div>
                          )}
                        </div>

                        <button
                          type="submit"
                          className="btn btn-primary w-100"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Processing..." : "Submit Order"}
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <Navbar />
      <div className="container">{state.length === 0 ? EmptyCart() : ShowCheckout()}</div>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Checkout;
