import React from "react";
import ReactDOM from "react-dom/client";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./index.css";
import ProtectedRoute from "./pages/ProtectedRoute";
import ScrollToTop from "./components/ScrollToTop";
import { Toaster } from "react-hot-toast";

// Page Imports
import {
  Home,
  AboutPage,
  ContactPage,
  Cart,
  Login,
  Register,
  Checkout,
  PageNotFound,
  Product,
} from "./pages";
import Products from "./components/Products";
import AdminHome from "./pages/AdminHome";
import ProtectedCheckout from "./pages/ProtectedCheckout";
import GetCheckoutByUserId from "./components/GetCheckoutByUserId";
import Profile from "./components/Profile";

// Root Render
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <ScrollToTop>
      <Provider store={store}>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Products />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<PageNotFound />} />


          <Route
            path="/checkout"
            element={
              <ProtectedCheckout>
                <Checkout />
              </ProtectedCheckout>
            }
          />
          <Route
            path="/usercheckout/:userId"
            element={
              <ProtectedCheckout>
               <GetCheckoutByUserId />
              </ProtectedCheckout>
            }
          />

          {/* Admin Routes */}
          <Route path="/admin/login" element={<Login />} />
          <Route
            path="/admin/unauthorized"
            element={<h1>Unauthorized</h1>}
          />
          <Route
            path="/admin/*"
            element={
              <ProtectedRoute>
                <AdminHome />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Provider>
    </ScrollToTop>
    <Toaster />
  </BrowserRouter>
);
