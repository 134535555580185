import React, { useState, useEffect } from "react";
import axios from "axios";
import { CHECKOUT_API_END_POINT } from "../utils/constant";
import { useParams } from "react-router-dom";
import Navbar from "./Navbar";

const GetCheckoutByUserId = () => {
  const { userId } = useParams(); // Get userId from URL params
  const [checkouts, setCheckouts] = useState([]); // Checkout data
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch checkouts when the component mounts or when userId changes
  useEffect(() => {
    if (!userId) return; // Don't fetch if no userId

    const fetchCheckouts = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await axios.get(`${CHECKOUT_API_END_POINT}/listbyuserid/${userId}`);
        const data = response.data;

        if (data.success && Array.isArray(data.checkouts)) {
          setCheckouts(data.checkouts);
        } else {
          setCheckouts([]);
          console.error("Unexpected data format:", data);
        }
      } catch (err) {
        console.error("Error fetching user checkouts:", err);
        setError("Failed to retrieve checkouts. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchCheckouts();
  }, [userId]); // Re-fetch when userId changes

  return (
    <>
    <Navbar />
    <div className="container mt-4">

      {loading && <p>Loading...</p>}
      {error && <p className="text-danger">{error}</p>}
      {!loading && !error && checkouts.length === 0 && (
        <p>No checkouts found for the given User ID.</p>
      )}
      {!loading && !error && checkouts.length > 0 && (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Product Title</th>
              <th>Price</th>
              <th>Payment Screenshot</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {checkouts.map((checkout, index) => (
              <tr key={checkout._id}>
                <td>{index + 1}</td>
                <td>
                  {checkout.products?.map((product) => (
                    <div key={product._id}>
                      <strong>{product.title}</strong>
                    </div>
                  ))}
                </td>
                <td>
                  {checkout.products?.map((product) => (
                    <div key={product._id}>${product.price}</div>
                  ))}
                </td>
                <td>
                  {checkout.qrScreenshot?.url ? (
                    <img
                      src={checkout.qrScreenshot.url}
                      alt="QR Screenshot"
                      style={{ width: "50px", height: "50px" }}
                    />
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>{checkout.status || "Pending"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
    </>
  );
};

export default GetCheckoutByUserId;
