import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedCheckout = ({ children }) => {
  // Retrieve the user object from Redux store
  const user = useSelector((state) => state.auth.user);


  // Check if user is logged in
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedCheckout;
