import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
const AdminNav = () => {
  

  // Retrieve the admin object from Redux store
  const user = useSelector(state => state.auth.user);

  // Safely access the admin's name property
  const adminName = user?.fullname || 'Admin !!'; // Default to "Admin" if the name is not available

  const handleLogout = () => {
  };

  return (
    <nav className="navbar navbar-dark bg-dark px-3">
      <div className="container-fluid">
        <span className="navbar-brand mb-0 h1">Admin Dashboard</span>
        <ul className="navbar-nav d-flex flex-row align-items-center">
          <li className="nav-item px-2">
            <NavLink className="nav-link text-light" to="/admin/dashboard">
              Dashboard
            </NavLink>
          </li>
          <li className="nav-item px-2">
            <NavLink className="nav-link text-light" to="/admin/users">
              Manage Users
            </NavLink>
          </li>
          <li className="nav-item px-2">
            <NavLink className="nav-link text-light" to="/admin/settings">
              Settings
            </NavLink>
          </li>
        </ul>
        <div className="d-flex align-items-center">
          {/* Display the admin's name */}
          <span className="text-light me-3">Welcome, {adminName}!</span>
          <button className="btn btn-outline-light" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
};

export default AdminNav;
