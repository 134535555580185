const Sidebar = ({ selectedPage, setSelectedPage }) => {
  const menuItems = [
    { id: "addProduct", label: "Add Product" },
    { id: "confirmOrders", label: "Confirm Orders" },
    { id: "checkout", label: "Checkout" },
  ];

  const getButtonStyle = (id) => ({
    backgroundColor: selectedPage === id ? "green" : "transparent",
    color: selectedPage === id ? "white" : "black",
    border: "none",
    padding: "10px 15px",
    cursor: "pointer",
    width: "100%",
    textAlign: "left",
  });

  return (
    <nav className="col-md-3 col-lg-2 d-md-block bg-light sidebar">
      <div className="position-sticky">
        <ul className="nav flex-column">
          {menuItems.map((item) => (
            <li key={item.id} className="nav-item">
              <button
                style={getButtonStyle(item.id)}
                className="nav-link btn btn-link"
                onClick={() => setSelectedPage(item.id)}
              >
                {item.label}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
