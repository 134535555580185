import React from "react";
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

const Home = () => {
  return (
    <>
      <MDBCarousel showControls fade >
        <MDBCarouselItem itemId={1}>
          <img 
            src='https://mdbootstrap.com/img/new/slides/041.jpg' 
            className='d-block w-100' 
            alt='...' 
            style={{ height: '100%', width: '100%', objectFit: 'cover' }} 
          />
        </MDBCarouselItem>
        <MDBCarouselItem itemId={2}>
          <img 
            src='https://mdbootstrap.com/img/new/slides/042.jpg' 
            className='d-block w-100' 
            alt='...' 
            style={{ height: '100%', width: '100%', objectFit: 'cover' }} 
          />
        </MDBCarouselItem>
        <MDBCarouselItem itemId={3}>
          <img 
            src='https://mdbootstrap.com/img/new/slides/043.jpg' 
            className='d-block w-100' 
            alt='...' 
            style={{ height: '100%', width: '100%', objectFit: 'cover' }} 
          />
        </MDBCarouselItem>
      </MDBCarousel>
    </>
  );
};

export default Home;
