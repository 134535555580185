import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CHECKOUT_API_END_POINT } from "../utils/constant";

const Checkout = () => {
  const [checkouts, setCheckouts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  useEffect(() => {
    fetchCheckouts();
  }, []);

  const fetchCheckouts = async () => {
    try {
      const response = await axios.get(`${CHECKOUT_API_END_POINT}/listall`);
      if (response.data.success) {
        setCheckouts(response.data.checkouts || []);
      } else {
        setError(response.data.message || "Failed to fetch data.");
      }
    } catch (error) {
      console.error("Error fetching checkouts:", error);
      setError("An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateStatus = async (id, newStatus) => {
    try {
      console.log(`Updating checkout ID: ${id} with new status: ${newStatus}`);
    
      const response = await axios.put(`${CHECKOUT_API_END_POINT}/updatelistbyid/${id}`, {
        status: newStatus,
      });
  
      if (response.data.success) {
        toast.success("Status updated successfully!");
        console.log("Status update successful:", response.data);
  
        setCheckouts((prevCheckouts) => {
          const updatedCheckouts = prevCheckouts.map((checkout) =>
            checkout._id === id ? { ...checkout, status: newStatus } : checkout
          );
          return updatedCheckouts;
        });
      } else {
        toast.error(response.data.message || "Failed to update status.");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error(error.response?.data?.message || "An error occurred while updating the status.");
    }
  };

  const handleShowModal = (image) => {
    setCurrentImage(image);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentImage("");
  };

  return (
    <div className="container mt-4">
      <h2>Admin Checkout Table</h2>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />

      {loading && <p>Loading data...</p>}
      {error && <p className="text-danger">{error}</p>}
      {!loading && !error && checkouts.length === 0 && <p>No checkouts available.</p>}
      {!loading && !error && checkouts.length > 0 && (
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>#</th>
              <th>User</th>
              <th>Remarks</th>
              <th>Sender Number</th>
              <th>QR Screenshot</th>
              <th>Products</th>
              <th>Status</th>
              <th>Created At</th> {/* Added Created At column */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {checkouts.map((checkout, index) => (
              <tr key={checkout._id}>
                <td>{index + 1}</td>
                <td>
                  <strong>Name:</strong> {checkout.user?.fullname || "N/A"} <br />
                  <strong>Email:</strong> {checkout.user?.email || "N/A"} <br />
                  <strong>Phone:</strong> {checkout.user?.phoneNumber || "N/A"}
                </td>
                <td>{checkout.remarks || "N/A"}</td>
                <td>{checkout.senderNumber}</td>
                <td>
                  {checkout.qrScreenshot?.url ? (
                    <>
                      <img
                        src={checkout.qrScreenshot.url}
                        alt="QR Screenshot"
                        style={{ width: "100px", height: "100px", cursor: "pointer" }}
                        onClick={() => handleShowModal(checkout.qrScreenshot.url)}
                      />
                      <p
                        style={{
                          margin: 0,
                          fontSize: "12px",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={() => handleShowModal(checkout.qrScreenshot.url)}
                      >
                        View Image
                      </p>
                    </>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>
                  <ul>
                    {checkout.products?.map((product) => (
                      <li key={product._id}>
                        <img
                          src={product.image.url}
                          alt={product.title}
                          style={{ width: "50px", marginRight: "10px" }}
                        />
                        <strong>{product.title}</strong> - ${product.price}
                      </li>
                    ))}
                  </ul>
                </td>
                <td>{checkout.status || "Pending"}</td>
                <td>{new Date(checkout.createdAt).toLocaleString()}</td> {/* Display Created At */}
                <td>
                  {['accepted', 'pending', 'delivering', 'completed', 'cancelled'].map((statusOption) => (
                    <button
                      key={statusOption}
                      className={`btn btn-sm ${
                        checkout.status === statusOption ? "btn-secondary" : "btn-primary"
                      }`}
                      disabled={checkout.status === statusOption}
                      onClick={() => handleUpdateStatus(checkout._id, statusOption)}
                    >
                      {statusOption.charAt(0).toUpperCase() + statusOption.slice(1)}
                    </button>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Modal for QR Screenshot */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>QR Screenshot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={currentImage}
            alt="QR Screenshot"
            style={{ width: "100%", height: "auto" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Checkout;
