import React from "react";
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom'; // Import Link component

const Footer = () => {
  return (
    <>
      <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
        <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
          <div className='me-5 d-none d-lg-block'>
            <span>Get connected with us on social networks:</span>
          </div>

          <div>
            <Link to="https://www.facebook.com" className='me-4 text-reset' target="_blank" rel="noopener noreferrer">
              <MDBIcon fab icon="facebook-f" />
            </Link>
            <Link to="https://www.twitter.com" className='me-4 text-reset' target="_blank" rel="noopener noreferrer">
              <MDBIcon fab icon="twitter" />
            </Link>
            <Link to="https://www.google.com" className='me-4 text-reset' target="_blank" rel="noopener noreferrer">
              <MDBIcon fab icon="google" />
            </Link>
            <Link to="https://www.instagram.com" className='me-4 text-reset' target="_blank" rel="noopener noreferrer">
              <MDBIcon fab icon="instagram" />
            </Link>
            <Link to="https://www.linkedin.com" className='me-4 text-reset' target="_blank" rel="noopener noreferrer">
              <MDBIcon fab icon="linkedin" />
            </Link>
            <Link to="https://www.github.com" className='me-4 text-reset' target="_blank" rel="noopener noreferrer">
              <MDBIcon fab icon="github" />
            </Link>
          </div>
        </section>

        <section className=''>
          <MDBContainer className='text-center text-md-start mt-5'>
            <MDBRow className='mt-3'>
              <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>
                  <MDBIcon icon="gem" className="me-3" />
                  diGI Store
                </h6>
                <p>
                  best product in the world
                </p>
              </MDBCol>

              <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Products</h6>

              </MDBCol>

              <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
                <Link to='/pricing' className='text-reset'>
                  Pricing
                </Link>
                <Link to='/settings' className='text-reset'>
                  Settings
                </Link>
                <Link to='/orders' className='text-reset'>
                  Orders
                </Link>
                <Link to='/help' className='text-reset'>
                  Help
                </Link>
              </MDBCol>

              <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                <p>
                  <MDBIcon icon="home" className="me-2" />
                  Nepal
                </p>
                <p>
                  <MDBIcon icon="envelope" className="me-3" />
                  info@example.com
                </p>
                <p>
                  <MDBIcon icon="phone" className="me-3" /> + 01 234 567 88
                </p>
                <p>
                  <MDBIcon icon="print" className="me-3" /> + 01 234 567 89
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
          © 2024 Copyright:
          <Link to='github/digi-store' className='text-reset fw-bold' target="_blank" rel="noopener noreferrer">
            diGI Store
            
          </Link>
        </div>
      </MDBFooter>
    </>
  );
};

export default Footer;
