import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ADMIN_API_END_POINT } from "../utils/constant";

const AddProduct = () => {
  const [form, setForm] = useState({
    title: "",
    price: "",
    description: "",
    category: "electronics",
    image: null,
  });
  const [loading, setLoadingState] = useState(false);
  const token = useSelector((state) => state.auth.user.token);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleFileChange = (e) => {
    setForm({ ...form, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!token) {
      toast.error("You are not authorized. Please log in.");
      return;
    }

    if (!form.image) {
      toast.error("Please upload a product image.");
      return;
    }

    setLoadingState(true);

    const formData = new FormData();
    formData.append("title", form.title);
    formData.append("price", form.price);
    formData.append("description", form.description);
    formData.append("category", form.category);
    formData.append("image", form.image);

    try {
      const response = await axios.post(`${ADMIN_API_END_POINT}/add-product`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        toast.success("Product added successfully!");
        setForm({
          title: "",
          price: "",
          description: "",
          category: "electronics",
          image: null,
        });
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An error occurred while adding the product."
      );
    } finally {
      setLoadingState(false);
    }
  };

  return (
    <div>
      <div className="card shadow mt-3">
        <div className="card-header bg-primary text-white">
          <h4>Add New Product</h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Product Title</label>
              <input
                type="text"
                className="form-control"
                name="title"
                value={form.title}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Price</label>
              <input
                type="number"
                className="form-control"
                name="price"
                value={form.price}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Description</label>
              <textarea
                className="form-control"
                name="description"
                value={form.description}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Category</label>
              <select
                className="form-control"
                name="category"
                value={form.category}
                onChange={handleChange}
                required
              >
                <option value="electronics">Electronics</option>
                <option value="men's clothing">Men's Clothing</option>
                <option value="jewelry">Jewelry</option>
                <option value="women's clothing">Women's Clothing</option>
              </select>
            </div>
            <div className="mb-3">
              <label className="form-label">Product Image</label>
              <input
                type="file"
                className="form-control"
                onChange={handleFileChange}
                required
              />
            </div>
            <button type="submit" className="btn btn-success" disabled={loading}>
              {loading ? "Adding..." : "Add Product"}
            </button>
          </form>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
    </div>
  );
};

export default AddProduct;
