import React, { useState } from 'react';
import { useSelector } from 'react-redux'; // Adjust the path as needed

const Profile = () => {
  const [open, setOpen] = useState(false);
  
  // Access the user state from Redux store
  const user = useSelector(state => state.auth.user);

  return (
    <>
      <div className="container my-5 p-4 border rounded">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center gap-4">
            <div className="h-24 w-24">
              {user?.profilePhoto ? (
                <img src={user.profilePhoto} alt="profile" className="rounded-circle" />
              ) : (
                <div className="bg-secondary text-white rounded-circle d-flex justify-content-center align-items-center" style={{ height: "96px", width: "96px" }}>
                  {user?.fullname?.charAt(0)}
                </div>
              )}
            </div>
            <div>
              <h1 className="font-weight-bold">{user?.fullname}</h1>
              <p>{user?.profile?.bio || 'No bio available'}</p>
            </div>
          </div>
          <button onClick={() => setOpen(true)} className="btn btn-outline-secondary">
            Edit Profile
          </button>
        </div>
        <div className="my-4">
          <div className="d-flex align-items-center gap-3 my-2">
            <span>Email: {user?.email}</span>
          </div>
          <div className="d-flex align-items-center gap-3 my-2">
            <span>Phone: {user?.phoneNumber}</span>
          </div>
        </div>
      </div>

      {/* Edit Profile Modal */}
      {open && (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-content">
            <h2>Edit Profile</h2>
            <form>
              {/* Your form fields for editing profile */}
              <div className="form-group">
                <label htmlFor="bio">Bio</label>
                <textarea id="bio" defaultValue={user?.profile?.bio} className="form-control"></textarea>
              </div>
              <div className="form-group mt-3">
                <label htmlFor="email">Email</label>
                <input id="email" type="email" defaultValue={user?.email} className="form-control" disabled />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="phoneNumber">Phone</label>
                <input id="phoneNumber" type="text" defaultValue={user?.phoneNumber} className="form-control" />
              </div>
              <div className="mt-3">
                <button type="button" className="btn btn-secondary" onClick={() => setOpen(false)}>
                  Close
                </button>
                <button disabled className="btn btn-primary ml-2">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="container mt-4 d-flex justify-content-between">
        <span className="text-muted">Welcome, {user?.fullname}!</span>
        <button className="btn btn-outline-danger">
          Logout
        </button>
      </div>
    </>
  );
};

export default Profile;
